<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Boardcast</h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-6">
          <!-- MultipleCard -->
          <card header-classes="bg-transparent">
            <template v-slot:header>
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="h3 mb-0">บน</h5>
                </div>
              </div>
            </template>
            <div class="row">
              <label class="form-control-label"
                >รูปภาพ
                <span class="text-xs">(ขนาดแนะนำ 1140x460 px)</span></label
              >
              <input
                class="form-control"
                ref="fileupload"
                type="file"
                @change="ImgfilesChangeBc"
              />
            </div>
            <div
              class="row w-100 mt-3 justify-content-center"
              v-if="Bc.previewImage"
            >
              <div>
                <img
                  style="max-width: 100px; height: auto"
                  :src="Bc.previewImage"
                  alt="previewImage Bc"
                />
              </div>
            </div>
            <div class="row w-100 mt-3 justify-content-center">
              <button class="btn btn-base btn-primary w-75" @click="onAddBc">
                เพิ่ม
              </button>
            </div>
            <div class="row mt-4">
              <div
                class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <div class="form-group">
                  <label for="selectshow" class="form-control-label"
                    >แสดง</label
                  >
                  <el-select
                    class="select-primary pagination-select mx-2"
                    v-model="Bc.pagination.perPage"
                    placeholder="Per page"
                    style="width: 4.5rem"
                    id="selectshow"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in Bc.pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                  <label for="selectshow" class="form-control-label"
                    >รายการ</label
                  >
                </div>
                <base-pagination
                  class="pagination-no-border"
                  v-model="Bc.pagination.currentPage"
                  :per-page="Bc.pagination.perPage"
                  :total="totalBc"
                >
                </base-pagination>
              </div>
            </div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-table
                v-loading="Bc.loading"
                :data="queriedDataBc"
                header-row-class-name="thead-light"
              >
                <el-table-column
                  min-width="280px"
                  align="center"
                  label="รูปภาพ"
                >
                  <template v-slot:default="props">
                    <div class="font-weight-bold">
                      <img
                        v-if="props.row.img"
                        :src="imagePath + props.row.img"
                        class="rounded"
                        style="max-height: 70px; width: auto"
                      />
                    </div>
                  </template>
                </el-table-column>
                <el-table-column min-width="130px" align="center" label="ลบ">
                  <template v-slot:default="props">
                    <button
                      class="btn base-button btn-sm btn-danger rounded-circle"
                      @click="onDelete(props.row.id, 'bc')"
                    >
                      <i class="fa fa-trash-alt"></i>
                    </button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <template v-slot:footer>
              <div
                class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <div class="">
                  <p class="card-category">
                    แสดง {{ fromBc + 1 }} ถึง {{ toBc }} จากทั้งหมด
                    {{ totalBc }} รายการ
                  </p>
                </div>
              </div>
            </template>
          </card>
        </div>
        <div class="col-xl-6">
          <!-- MultipleCard -->
          <card header-classes="bg-transparent">
            <template v-slot:header>
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="h3 mb-0">ล่าง</h5>
                </div>
              </div>
            </template>
            <div class="row">
              <label class="form-control-label">รูปภาพ <span class="text-xs">(ขนาดแนะนำ 1140x460 px)</span></label>
              <input
                class="form-control"
                ref="fileupload"
                type="file"
                @change="ImgfilesChangeBp"
              />
            </div>
            <div
              class="row w-100 mt-3 justify-content-center"
              v-if="Bp.previewImage"
            >
              <div>
                <img
                  style="max-width: 100px; height: auto"
                  :src="Bp.previewImage"
                  alt="previewImage Bp"
                />
              </div>
            </div>
            <div class="row w-100 mt-3 justify-content-center">
              <button class="btn btn-base btn-primary w-75" @click="onAddBp">
                เพิ่ม
              </button>
            </div>
            <div class="row mt-4">
              <div
                class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <div class="form-group">
                  <label for="selectshow" class="form-control-label"
                    >แสดง</label
                  >
                  <el-select
                    class="select-primary pagination-select mx-2"
                    v-model="Bp.pagination.perPage"
                    placeholder="Per page"
                    style="width: 4.5rem"
                    id="selectshow"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in Bp.pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                  <label for="selectshow" class="form-control-label"
                    >รายการ</label
                  >
                </div>
                <base-pagination
                  class="pagination-no-border"
                  v-model="Bp.pagination.currentPage"
                  :per-page="Bp.pagination.perPage"
                  :total="totalBp"
                >
                </base-pagination>
              </div>
            </div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-table
                v-loading="Bp.loading"
                :data="queriedDataBp"
                header-row-class-name="thead-light"
              >
                <el-table-column
                  min-width="280px"
                  align="center"
                  label="รูปภาพ"
                >
                  <template v-slot:default="props">
                    <div class="font-weight-bold">
                      <img
                        v-if="props.row.img"
                        :src="imagePath + props.row.img"
                        class="rounded"
                        style="max-height: 70px; width: auto"
                      />
                    </div>
                  </template>
                </el-table-column>
                <el-table-column min-width="130px" align="center" label="ลบ">
                  <template v-slot:default="props">
                    <button
                      class="btn base-button btn-sm btn-danger rounded-circle"
                      @click="onDelete(props.row.id, 'bp')"
                    >
                      <i class="fa fa-trash-alt"></i>
                    </button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <template v-slot:footer>
              <div
                class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <div class="">
                  <p class="card-category">
                    แสดง {{ fromBp + 1 }} ถึง {{ toBp }} จากทั้งหมด
                    {{ totalBp }} รายการ
                  </p>
                </div>
              </div>
            </template>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "../services/axios";
import { useToast } from "vue-toastification";
import "sweetalert2/dist/sweetalert2.css";
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import BasePagination from "@/components/BasePagination";

const toast = useToast();

export default {
  name: "BoardcastPage",
  components: {
    BasePagination,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
  },
  data() {
    return {
      imagePath: process.env.VUE_APP_IMAGE_PATH,
      Bc: {
        previewImage: "",
        image: undefined,
        //
        //
        searchQuery: "",
        propsToSearch: [],
        tableData: [],
        searchedData: [],
        pagination: {
          perPage: 10,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50],
          total: 0,
        },
        loading: false,
      },
      Bp: {
        previewImage: "",
        image: undefined,
        //
        //
        searchQuery: "",
        propsToSearch: [],
        tableData: [],
        searchedData: [],
        pagination: {
          perPage: 10,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50],
          total: 0,
        },
        loading: false,
      },
    };
  },
  created() {
    this.fetchBc();
    this.fetchBp();
  },
  methods: {
    fetchBc() {
      this.Bc.loading = true;
      axios
        .get("/boardcast")
        .then((resp) => {
          this.Bc.loading = false;
          if (resp?.data?.status === "success") {
            this.Bc.tableData = resp?.data?.data;
          }
        })
        .catch((err) => {
          this.Bc.loading = false;
          if (err?.response) {
            if (err?.response?.status !== 401 && err?.response?.status !== 403) {
              toast.error(
                err?.response?.data?.message
                  ? err?.response?.data?.message
                  : "ไม่สามารถโหลดข้อมูล Boardcast ได้"
              );
            } else {
              this.$store.dispatch("logoutAuth");
              localStorage.removeItem("AUTH");
              this.$router.push("/login");
            }
          }
        });
    },
    fetchBp() {
      this.Bp.loading = true;
      axios
        .get("/boardcast/pro")
        .then((resp) => {
          this.Bp.loading = false;
          if (resp?.data?.status === "success") {
            this.Bp.tableData = resp?.data?.data;
          }
        })
        .catch((err) => {
          this.Bp.loading = false;
          if (err?.response) {
            if (err?.response?.status !== 401 && err?.response?.status !== 403) {
              toast.error(
                err?.response?.data?.message
                  ? err?.response?.data?.message
                  : "ไม่สามารถโหลดข้อมูล Boardcast Promotion ได้"
              );
            } else {
              this.$store.dispatch("logoutAuth");
              localStorage.removeItem("AUTH");
              this.$router.push("/login");
            }
          }
        });
    },
    ImgfilesChangeBc(event) {
      if (event.target.files) {
        this.Bc.image = event.target.files[0];
        this.Bc.previewImage = URL.createObjectURL(this.Bc.image);
      }
    },
    ImgfilesChangeBp(event) {
      if (event.target.files) {
        this.Bp.image = event.target.files[0];
        this.Bp.previewImage = URL.createObjectURL(this.Bp.image);
      }
    },
    onAddBc() {
      if (this.Bc.image) {
        let fromData = new FormData();
        fromData.append("img", this.Bc.image);

        axios
          .post("/admin/boardcast/add", fromData, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("AUTH"),
            },
          })
          .then((resp) => {
            if (resp?.data?.status === "success") {
              toast.success("สำเร็จ");
              this.Bc.previewImage = "";
              this.fetchBc();
            }
          })
          .catch((err) => {
            if (err?.response) {
              if (err?.response?.status !== 401 && err?.response?.status !== 403) {
                toast.error(
                  err?.response?.data?.message
                    ? err?.response?.data?.message
                    : "ไม่สามารถเพิ่มรายการได้"
                );
              } else {
                this.$store.dispatch("logoutAuth");
                localStorage.removeItem("AUTH");
                this.$router.push("/login");
              }
            }
          });
      } else {
        toast.error("ไม่พบรูปภาพ");
      }
    },
    onAddBp() {
      if (this.Bp.image) {
        let fromData = new FormData();
        fromData.append("img", this.Bp.image);

        axios
          .post("/admin/boardcast/pro/add", fromData, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("AUTH"),
            },
          })
          .then((resp) => {
            if (resp?.data?.status === "success") {
              toast.success("สำเร็จ");
              this.Bp.previewImage = "";
              this.fetchBp();
            }
          })
          .catch((err) => {
            if (err?.response) {
              if (err?.response?.status !== 401 && err?.response?.status !== 403) {
                toast.error(
                  err?.response?.data?.message
                    ? err?.response?.data?.message
                    : "ไม่สามารถเพิ่มรายการได้"
                );
              } else {
                this.$store.dispatch("logoutAuth");
                localStorage.removeItem("AUTH");
                this.$router.push("/login");
              }
            }
          });
      } else {
        toast.error("ไม่พบรูปภาพ");
      }
    },
    onDelete(id, cate) {
      let api =
        cate === "bc"
          ? "/admin/boardcast/remove"
          : "/admin/boardcast/pro/remove";
      axios
        .post(
          api,
          {
            id: id,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("AUTH"),
            },
          }
        )
        .then((resp) => {
          if (resp?.data?.status === "success") {
            toast.success("ลบรายการสำเร็จ");
            if (cate === "bc") {
              this.fetchBc();
            } else {
              this.fetchBp();
            }
          }
        })
        .catch((err) => {
          if (err?.response) {
            if (err?.response?.status !== 401 && err?.response?.status !== 403) {
              toast.error(
                err?.response?.data?.message
                  ? err?.response?.data?.message
                  : "ไม่สามารถลบรายการได้"
              );
            } else {
              this.$store.dispatch("logoutAuth");
              localStorage.removeItem("AUTH");
              this.$router.push("/login");
            }
          }
        });
    },
  },
  computed: {
    ...mapGetters(["IsLoggedIn"]),
    // BC Table
    pagedDataBc() {
      return this.Bc.tableData.slice(this.fromBc, this.toBc);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedDataBc}
     */
    queriedDataBc() {
      if (!this.Bc.searchQuery) {
        return this.pagedDataBc;
      }
      let result = this.Bc.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.Bc.propsToSearch) {
          let rowValue = row[key].toString();
          if (rowValue.includes && rowValue.includes(this.Bc.searchQuery)) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.fromBc, this.toBc);
    },
    toBc() {
      let highBound = this.fromBc + this.Bc.pagination.perPage;
      if (this.totalBc < highBound) {
        highBound = this.totalBc;
      }
      return highBound;
    },
    fromBc() {
      return this.Bc.pagination.perPage * (this.Bc.pagination.currentPage - 1);
    },
    totalBc() {
      return this.Bc.searchedData.length > 0
        ? this.Bc.searchedData.length
        : this.Bc.tableData.length;
    },
    // BP Table
    pagedDataBp() {
      return this.Bp.tableData.slice(this.fromBp, this.toBp);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedDataBp}
     */
    queriedDataBp() {
      if (!this.Bp.searchQuery) {
        return this.pagedDataBp;
      }
      let result = this.Bp.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.Bp.propsToSearch) {
          let rowValue = row[key].toString();
          if (rowValue.includes && rowValue.includes(this.Bp.searchQuery)) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.fromBp, this.toBp);
    },
    toBp() {
      let highBound = this.fromBp + this.Bp.pagination.perPage;
      if (this.totalBp < highBound) {
        highBound = this.totalBp;
      }
      return highBound;
    },
    fromBp() {
      return this.Bp.pagination.perPage * (this.Bp.pagination.currentPage - 1);
    },
    totalBp() {
      return this.Bp.searchedData.length > 0
        ? this.Bp.searchedData.length
        : this.Bp.tableData.length;
    },
  },
};
</script>

<style>
.el-table .el-table__header-wrapper thead th .cell {
  justify-content: center;
}
</style>
