<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">เมสเซนเจอร์</h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-12">
          <!-- MultipleCard -->
          <card header-classes="bg-transparent">
            <template v-slot:header>
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="h3 mb-0"></h5>
                </div>
              </div>
            </template>
            <div class="row">
              <div class="col-12 col-md-4"></div>
              <div class="col-12 col-md-4 text-center">
                <h3>เปิด / ปิด</h3>
                <div class="d-flex justify-content-center align-items-center">
                  <div
                    class="form-check mr-3"
                    v-for="(item, index) in stations"
                    :key="index"
                  >
                    <input
                      class="form-check-input"
                      type="checkbox"
                      style="cursor: pointer"
                      :checked="item.status"
                      @click="onEdit(item.id, item.status == 1 ? 0 : 1)"
                    />
                    <label class="form-check-label">
                      {{ getName(item.station) }}
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-4"></div>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import axios from "../services/axios";

const toast = useToast();

export default {
  name: "MessengerPage",
  components: {},
  data() {
    return {
      stations: [],
    };
  },
  created() {
    if (!this.IsLoggedIn) {
      this.$router.push("/login");
    } else {
      this.fetchStatus();
    }
  },
  methods: {
    getName(station) {
      if (station === "loei") {
        return "อ.เมือง";
      } else if (station === "wang") {
        return "อ.วังสะพุง";
      } else {
        return "อ.เชียงคาน";
      }
    },
    fetchStatus() {
      axios
        .get("/messenger")
        .then((resp) => {
          this.stations = resp.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onEdit(id, status) {
      axios
        .post(
          "/admin/messenger/status",
          {
            id: id,
            status: status,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("AUTH"),
            },
          }
        )
        .then(() => {
          toast.success("สำเร็จ");
          this.fetchStatus();
        })
        .catch(() => {
          toast.error("ไม่สามารถตั้งค่าได้");
        });
    },
  },
  computed: {
    ...mapGetters(["IsLoggedIn"]),
  },
};
</script>

<style></style>
