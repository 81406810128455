<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">ยอดขาย</h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-12">
          <!-- MultipleCard -->
          <card header-classes="bg-transparent">
            <div class="row justify-content-end mt-3 align-items-center">
              <div class="form-group mr-2">
                <select
                  class="form-control"
                  v-model="type"
                  @change="fetchSummaryData(); rider = 'all'"
                >
                  <option value="ในเมือง">ในเมือง</option>
                  <option value="ราชภัฏ">ราชภัฏ</option>
                  <option value="วังสะพุง">วังสะพุง</option>
                  <option value="เชียงคาน">เชียงคาน</option>
                  <option value="พัสดุ">พัสดุ</option>
                  <option value="หน้าร้าน">หน้าร้าน</option>
                </select>
              </div>
              <div class="d-flex align-items-center" v-if="type !== 'พัสดุ' && type !== 'หน้าร้าน'">
                <p class="mr-1">ไรเดอร์:</p>
                <div class="form-group mr-2">
                  <select
                    class="form-control"
                    v-model="rider"
                    @change="fetchSummaryData"
                  >
                    <option value="all">ทั้งหมด</option>
                    <option
                      :value="item.rider"
                      v-for="(item, index) in riderData"
                      :key="index"
                    >
                      {{ item.rider }}
                    </option>
                  </select>
                </div>
              </div>
              <base-input name="date" class="mr-3">
                <template v-slot="{ focus, blur }">
                  <flat-picker
                    @on-open="focus"
                    @on-close="blur"
                    :config="{ allowInput: true, mode: 'range' }"
                    class="form-control datepicker"
                    v-model="date"
                    @change="fetchSummaryData"
                  >
                  </flat-picker>
                </template>
              </base-input>
              <div class="form-group">
                <input
                  type="search"
                  v-model="searchQuery"
                  id="search"
                  class="form-control"
                  placeholder="ค้นหา"
                />
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-xl-2 col-md-6">
                <stats-card
                  title="ยอดสินค้า"
                  type="gradient-blue"
                  :sub-title="this.price.toString()"
                  icon="ni ni-active-40"
                >
                </stats-card>
              </div>
              <div class="col-xl-2 col-md-6">
                <stats-card
                  title="ส่วนลด"
                  type="gradient-orange"
                  :sub-title="this.discount.toString()"
                  icon="ni ni-chart-pie-35"
                >
                </stats-card>
              </div>
              <div class="col-xl-2 col-md-6">
                <stats-card
                  title="หมายเหตุ"
                  type="gradient-green"
                  :sub-title="this.nodePrice.toString()"
                  icon="ni ni-tag"
                >
                </stats-card>
              </div>
              <div class="col-xl-2 col-md-6">
                <stats-card
                  title="เพิ่มนิค"
                  type="gradient-red"
                  :sub-title="this.nic.toString()"
                  icon="ni ni-fat-add"
                >
                </stats-card>
              </div>
              <div class="col-xl-2 col-md-6">
                <stats-card
                  title="รวม"
                  type="gradient-info"
                  :sub-title="(this.totalPrice + this.nodePrice).toString()"
                  icon="ni ni-money-coins"
                >
                </stats-card>
              </div>
            </div>
            <div class="row" v-if="sumCate.length > 0">
              <div class="col-xl-2 col-md-4" v-for="(item, index) in sumCate" :key="index">
                <stats-card
                  :title="item.name"
                  type="gradient-info"
                  :sub-title="item.count"
                >
                </stats-card>
              </div>
            </div>
            <div
              class="row d-flex justify-content-end mb-3"
              style="margin-top: -15px"
            >
              <button type="button" class="btn btn-danger" @click="onDelete">
                ลบข้อมูล
              </button>
            </div>
            <div class="row">
              <div
                class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <div class="form-group">
                  <label for="selectshow" class="form-control-label"
                    >แสดง</label
                  >
                  <el-select
                    class="select-primary pagination-select mx-2"
                    v-model="pagination.perPage"
                    placeholder="Per page"
                    style="width: 4.5rem"
                    id="selectshow"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                  <label for="selectshow" class="form-control-label"
                    >รายการ</label
                  >
                </div>
                <base-pagination
                  class="pagination-no-border"
                  v-model="pagination.currentPage"
                  :per-page="pagination.perPage"
                  :total="total"
                >
                </base-pagination>
              </div>
            </div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-table
                v-loading="loading"
                :data="queriedData"
                header-row-class-name="thead-light"
              >
                <el-table-column
                  min-width="180px"
                  align="center"
                  label="สินค้า"
                >
                  <template v-slot:default="props">
                    <div class="font-weight-bold">
                      {{ props.row.name }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  min-width="120px"
                  align="center"
                  label="ตัวเลือก"
                >
                  <template v-slot:default="props">
                    <div class="font-weight-bold">
                      {{ props.row.flavor }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column min-width="120px" align="center" label="จำนวน">
                  <template v-slot:default="props">
                    <div class="font-weight-bold">
                      {{ props.row.count }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column min-width="120px" align="center" label="ราคา">
                  <template v-slot:default="props">
                    <div class="font-weight-bold">
                      {{ props.row.price }}
                    </div>
                  </template>
                </el-table-column>
                <!-- <el-table-column
                  min-width="100px"
                  align="center"
                  label="แก้ไข / ลบ"
                >
                  <template v-slot:default="props">
                    <button
                      class="btn base-button btn-sm btn-primary rounded-circle"
                      @click="
                        onOpenEditModal(
                          props.row.id,
                          props.row.count,
                          props.row.price
                        )
                      "
                    >
                      <i class="fa fa-edit"></i>
                    </button>
                    <button
                      class="btn base-button btn-sm btn-danger rounded-circle"
                      @click="onDelete(props.row.id)"
                    >
                      <i class="fa fa-trash-alt"></i>
                    </button>
                  </template>
                </el-table-column> -->
              </el-table>
            </div>
            <template v-slot:footer>
              <div
                class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <div class="">
                  <p class="card-category">
                    แสดง {{ from + 1 }} ถึง {{ to }} จากทั้งหมด
                    {{ total }} รายการ
                  </p>
                </div>
              </div>
            </template>
          </card>
        </div>
      </div>
    </div>
    <!-- EDIT MODAL -->
    <!-- <modal v-model:show="editModal.show" size="sm">
      <card
        type="secondary"
        header-classes="bg-transparent border-0"
        class="border-0 mb-0"
      >
        <template v-slot:header>
          <div class="text-center">
            <h3 class="modal-title">แก้ไข</h3>
          </div>
        </template>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="count" class="form-control-label">จำนวน</label>
              <input
                name="count"
                id="count"
                type="number"
                class="form-control input-group-alternative"
                v-model="editModal.count"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="price" class="form-control-label">ราคา</label>
              <input
                name="price"
                id="price"
                type="number"
                class="form-control input-group-alternative"
                v-model="editModal.price"
              />
            </div>
          </div>
        </div>
        <div class="text-right">
          <button type="submit" class="btn btn-primary" @click="onSubmitEdit">
            ยืนยัน
          </button>
          <base-button type="danger" @click="onCloseEditModal">ปิด</base-button>
        </div>
      </card>
    </modal> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "../services/axios";
import { useToast } from "vue-toastification";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import BasePagination from "@/components/BasePagination";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
// import modal from "../components/Modal.vue";

const toast = useToast();

export default {
  name: "TotalSalesPage",
  components: {
    BasePagination,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
    flatPicker,
    // modal,
  },
  data() {
    return {
      date: `${this.getDateNow()} to ${this.getDateNow()}`,
      type: "ในเมือง",
      searchQuery: "",
      propsToSearch: ["name", "flavor", "count", "price"],
      tableData: [],
      searchedData: [],
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      riderData: [],
      loading: false,
      rider: "all",
      price: 0,
      discount: 0,
      totalPrice: 0,
      nic: 0,
      nodePrice: 0,
      sumCate: [],
      notFoundCate: [],
      // editModal: {
      //   show: false,
      //   selected: null,
      //   count: 0,
      //   price: 0,
      // },
    };
  },
  created() {
    this.fetchSummaryData();
  },
  methods: {
    onDelete() {
      if (this.type && this.date) {
        swal
          .fire({
            title: `ลบข้อมูลของวันและสาขาที่เลือก ?`,
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-warning",
              cancelButton: "btn btn-primary",
            },
            showCancelButton: true,
            confirmButtonText: "ยืนยัน",
            cancelButtonText: "ยกเลิก",
            icon: "warning",
            reverseButtons: true,
          })
          .then((result) => {
            if (result.isConfirmed) {
              let start = this.date;
              let end = this.date;

              if (this.date.includes("to")) {
                const date = this.date.split(" to ");
                start = date[0];
                end = date[1];
              }

              axios
                .post(
                  `/admin/summary/remove`,
                  {
                    type: this.type,
                    start: start,
                    end: end,
                    rider: this.type !== "พัสดุ" && this.type !== "หน้าร้าน" ? this.rider : "all",
                  },
                  {
                    headers: {
                      Authorization: "Bearer " + localStorage.getItem("AUTH"),
                    },
                  }
                )
                .then((resp) => {
                  if (resp?.data?.status === "success") {
                    toast.success("ลบข้อมูลสำเร็จ");
                    this.rider = "all";
                    this.fetchSummaryData();
                  }
                })
                .catch((err) => {
                  if (err?.response) {
                    if (
                      err?.response?.status !== 401 &&
                      err?.response?.status !== 403
                    ) {
                      toast.error(
                        err?.response?.data?.message
                          ? err?.response?.data?.message
                          : "ไม่สามารถลบข้อมูลได้"
                      );
                    } else {
                      this.$store.dispatch("logoutAuth");
                      localStorage.removeItem("AUTH");
                      this.$router.push("/login");
                    }
                  }
                });
            }
          });
      }
    },
    fetchSummaryData() {
      let start = this.date;
      let end = this.date;

      if (this.date.includes("to")) {
        const date = this.date.split(" to ");
        start = date[0];
        end = date[1];
      }

      axios;
      let api = `/admin/summary?type=${this.type}&start=${start}&end=${end}`;
      if (this.type !== "พัสดุ" && this.type !== "หน้าร้าน") {
        api += `&rider=${this.rider}`;
      }

      axios
        .get(api, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("AUTH"),
          },
        })
        .then((resp) => {
          if (resp?.data?.status === "success") {
            let data = resp?.data?.detail;
            let price = 0;
            resp?.data?.nodes.map((item) => {
              price += item.price;
              data = [...data, {name: item.name, flavor: "", count: "", price: item.price}]
            })

            this.nodePrice = price;
            this.tableData = data;
            this.riderData = resp?.data?.rider;
            this.price = resp?.data?.summary?.product_price || 0;
            this.discount = resp?.data?.summary?.total_discount || 0;
            this.nic = resp?.data?.summary?.total_nic || 0;
            this.totalPrice = resp?.data?.summary?.total || 0;
            
            this.sumCate = resp?.data?.summaryCategory;
            this.notFoundCate = resp?.data?.notFoundCategory;
          }
        })
        .catch((err) => {
          if (err?.response) {
            if (
              err?.response?.status !== 401 &&
              err?.response?.status !== 403
            ) {
              toast.error(
                err?.response?.data?.message
                  ? err?.response?.data?.message
                  : "ไม่สามารถโหลดข้อมูลได้"
              );
            } else {
              this.$store.dispatch("logoutAuth");
              localStorage.removeItem("AUTH");
              this.$router.push("/login");
            }
          }
        });
    },
    getDateNow() {
      const today = new Date();
      const dd = String(today.getDate()).padStart(2, "0");
      const mm = String(today.getMonth() + 1).padStart(2, "0");
      const yyyy = today.getFullYear();

      return yyyy + "-" + mm + "-" + dd;
    },
  },
  computed: {
    ...mapGetters(["IsLoggedIn"]),
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString().toLowerCase();
          if (
            rowValue.includes &&
            rowValue.includes(this.searchQuery.toLowerCase())
          ) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
    // ================================
    pagedDataInfoModal() {
      return this.infoModal.tableData.slice(
        this.fromInfoModal,
        this.toInfoModal
      );
    },
  },
};
</script>

<style>
.el-table .el-table__header-wrapper thead th .cell {
  justify-content: center;
}
</style>
