import { createStore } from "vuex";

const token = localStorage.getItem("AUTH");
const initialLoggedIn = token ? true : false;

export default createStore({
    state: {
        isLoggedIn: initialLoggedIn,
        username: null,
    },
    mutations: {
        loginAuth(state, user) {
            state.isLoggedIn = true;
            state.username = user.username;
        },
        logoutAuth(state) {
            state.isLoggedIn = false;
            state.username = null;
        },
        saveProfile(state, user) {
            state.username = user.username;
        }
    },
    actions: {
        loginAuth(context, user) {
            context.commit("loginAuth", user);
        },
        logoutAuth(context) {
            context.commit("logoutAuth");
        },
        saveProfile(context, user) {
            context.commit("saveProfile", user);
        }
    },
    getters: {
        Username(state) {
            return state.username;
        },
        IsLoggedIn(state) {
            return state.isLoggedIn;
        },
    },
});
