<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">ยอดจำนวนสินค้า</h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-12">
          <!-- MultipleCard -->
          <card header-classes="bg-transparent">
            <template v-slot:header>
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="h3 mb-0"></h5>
                </div>
              </div>
            </template>
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group">
                  <label for="data_area">ข้อมูล</label>
                  <textarea
                    class="form-control"
                    id="data_area"
                    rows="25"
                    v-model="data"
                  ></textarea>
                </div>
                <div class="mt-4 text-center">
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="sumProducts()"
                  >
                    ยืนยัน
                  </button>
                  <button
                    type="button"
                    class="btn btn-warning"
                    @click="resetData()"
                  >
                    รีเซ็ตข้อมูล
                  </button>
                </div>
              </div>
              <div class="col-lg-6">
                <card header-classes="bg-transparent" class="mt-4">
                  <div class="row mt-4">
                    <div
                      class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                    >
                      <div class="form-group">
                        <label for="selectshow" class="form-control-label"
                          >แสดง</label
                        >
                        <el-select
                          class="select-primary pagination-select mx-2"
                          v-model="pagination.perPage"
                          placeholder="Per page"
                          style="width: 4.5rem"
                          id="selectshow"
                        >
                          <el-option
                            class="select-primary"
                            v-for="item in pagination.perPageOptions"
                            :key="item"
                            :label="item"
                            :value="item"
                          >
                          </el-option>
                        </el-select>
                        <label for="selectshow" class="form-control-label"
                          >รายการ</label
                        >
                      </div>
                      <base-pagination
                        class="pagination-no-border"
                        v-model="pagination.currentPage"
                        :per-page="pagination.perPage"
                        :total="total"
                      >
                      </base-pagination>
                    </div>
                  </div>
                  <div
                    class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                  >
                    <el-table
                      :data="queriedData"
                      header-row-class-name="thead-light"
                    >
                      <el-table-column align="center" label="ชื่อ">
                        <template v-slot:default="props">
                          <div class="font-weight-bold">
                            {{ props.row.name }}
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column align="center" label="จำนวน">
                        <template v-slot:default="props">
                          <div class="font-weight-bold">
                            {{ props.row.count }}
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column align="center" label="ราคา">
                        <template v-slot:default="props">
                          <div class="font-weight-bold">
                            {{ props.row.price }}
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column align="center" label="ราคารวม">
                        <template v-slot:default="props">
                          <div class="font-weight-bold">
                            {{ props.row.total }}
                          </div>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </card>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import { ElTable, ElTableColumn, ElSelect, ElOption } from "element-plus";
import BasePagination from "@/components/BasePagination";

const toast = useToast();

export default {
  name: "SumProductPage",
  components: {
    BasePagination,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
  },
  data() {
    return {
      data: "",
      findProducts: [],
      tableData: [],
      searchedData: [],
      searchQuery: "",
      propsToSearch: [],
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
    };
  },
  created() {
    if (!this.IsLoggedIn) {
      this.$router.push("/login");
    }
  },
  methods: {
    findLocation(el) {
      if (el.includes("(อ.")) {
        console.log(el.replace("(", "").replace(")", "").split(" ")[1]);
      }
    },
    sumProducts() {
      this.tableData = [];

      if (this.data != "") {
        const data = this.data.replaceAll("—", "-").split("-----");
        data.forEach((item) => {
          if (item) {
            let rItem = item.replaceAll(" : ", ":").replaceAll(": ", ":");
            let allFine = rItem.split("\n");

            allFine.forEach((element) => {
              if (element.includes(" = ") && element.includes(" | ")) {
                let name = element.split(" | ")[0];
                
                let count = parseFloat(
                  element.split(" | ")[1].trim().split(" x").at(-1).split(" ")[0].replace("x", "")
                );

                let price = parseFloat(
                  element.split(" | ")[1].split(" = ")[1].replace("฿", "")
                );

                let foundIndex = this.tableData.findIndex(
                  (el) => el.name === name && el.price === price / count
                );

                if (foundIndex == -1) {
                  this.tableData.push({
                    name: name,
                    count: count,
                    price: price / count,
                    total: price,
                  });
                } else {
                  this.tableData[foundIndex].count += count;
                  this.tableData[foundIndex].total += price;
                }
              }
            });

            this.tableData.sort((a, b) => a.name.localeCompare(b.name));
          }
        });
      } else {
        toast.error("ใส่ข้อมูล");
      }
    },
    resetData() {
      this.data = "";
      this.tableData = [];
    },
  },
  computed: {
    ...mapGetters(["IsLoggedIn"]),
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedDataBc}
     */
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString();
          if (rowValue.includes && rowValue.includes(this.searchQuery)) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
};
</script>

<style></style>
