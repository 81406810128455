<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">จัดการโปรโมชั่น</h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-6">
          <!--  -->
          <!-- MultipleCard Product -->
          <!--  -->
          <card header-classes="bg-transparent">
            <template v-slot:header>
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="h3 mb-0">โปรโมชั่นซื้อหลายชิ้น (สินค้า)</h5>
                </div>
              </div>
            </template>
            <div class="row">
              <div class="col-6">
                <select
                  class="form-control"
                  v-model="multipleProduct.categorySelect"
                  @change="fetchProductByCategory"
                >
                  <option value="" disabled selected>--เลือกหมวดหมู่--</option>
                  <option
                    :value="item.name"
                    v-for="(item, index) in categoryData"
                    :key="index"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
              <div class="col-6">
                <select
                  class="form-control"
                  v-model="multipleProduct.productSelect"
                >
                  <option value="" disabled selected>--เลือกสินค้า--</option>
                  <option
                    :value="item.name"
                    v-for="(item, index) in multipleProduct.productData"
                    :key="index"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-4">
                <label class="form-control-label">จำนวนเริ่มต้น</label>
                <input
                  v-model="multipleProduct.start"
                  type="number"
                  class="form-control"
                />
              </div>
              <div class="col-4">
                <label class="form-control-label">จำนวนสิ้นสุด</label>
                <input
                  v-model="multipleProduct.end"
                  type="number"
                  class="form-control"
                />
              </div>
              <div class="col-4">
                <label class="form-control-label"
                  >ลด <span class="text-xs">(ส่วนลดต่อชิ้น)</span></label
                >
                <input
                  v-model="multipleProduct.discount"
                  type="number"
                  class="form-control"
                />
              </div>
            </div>
            <div class="row w-100 mt-3 justify-content-center">
              <button
                class="btn btn-base btn-primary w-75"
                @click="onAddMultiProduct"
              >
                เพิ่ม
              </button>
            </div>
            <div class="row justify-content-end mt-3">
              <div class="form-group">
                <input
                  type="search"
                  v-model="multipleProduct.searchQuery"
                  id="search"
                  class="form-control"
                  placeholder="ค้นหา"
                />
              </div>
            </div>
            <div class="row">
              <div
                class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <div class="form-group">
                  <label for="selectshow" class="form-control-label"
                    >แสดง</label
                  >
                  <el-select
                    class="select-primary pagination-select mx-2"
                    v-model="multipleProduct.pagination.perPage"
                    placeholder="Per page"
                    style="width: 4.5rem"
                    id="selectshow"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in multipleProduct.pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                  <label for="selectshow" class="form-control-label"
                    >รายการ</label
                  >
                </div>
                <base-pagination
                  class="pagination-no-border"
                  v-model="multipleProduct.pagination.currentPage"
                  :per-page="multipleProduct.pagination.perPage"
                  :total="totalMultipleProduct"
                >
                </base-pagination>
              </div>
            </div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-table
                v-loading="multipleProduct.loading"
                :data="queriedDataMultipleProduct"
                header-row-class-name="thead-light"
              >
                <el-table-column
                  min-width="170px"
                  align="center"
                  label="ชื่อสินค้า"
                >
                  <template v-slot:default="props">
                    <div class="font-weight-bold">
                      {{ props.row.name }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  min-width="120px"
                  align="center"
                  label="จำนวนเริ่มต้น"
                >
                  <template v-slot:default="props">
                    <div class="font-weight-bold">
                      {{ props.row.start }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  min-width="120px"
                  align="center"
                  label="จำนวนสิ้นสุด"
                >
                  <template v-slot:default="props">
                    <div class="font-weight-bold">
                      {{ props.row.end }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column min-width="100px" align="center" label="ลด">
                  <template v-slot:default="props">
                    <div class="font-weight-bold">
                      {{ props.row.discount }} บาท
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  min-width="130px"
                  align="center"
                  label="แก้ไข/ลบ"
                >
                  <template v-slot:default="props">
                    <button
                      class="btn base-button btn-sm btn-primary rounded-circle"
                      @click="openEditModal(props.$index)"
                    >
                      <i class="fa fa-edit"></i>
                    </button>
                    <button
                      class="btn base-button btn-sm btn-danger rounded-circle"
                      @click="onDeleteMultipleProduct(props.row.id)"
                    >
                      <i class="fa fa-trash-alt"></i>
                    </button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <template v-slot:footer>
              <div
                class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <div class="">
                  <p class="card-category">
                    แสดง {{ fromMultipleProduct + 1 }} ถึง
                    {{ toMultipleProduct }} จากทั้งหมด
                    {{ totalMultipleProduct }} รายการ
                  </p>
                </div>
              </div>
            </template>
          </card>
        </div>
        <div class="col-xl-6">
          <!--  -->
          <!-- AssortedCard Category -->
          <!--  -->
          <card header-classes="bg-transparent">
            <template v-slot:header>
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="h3 mb-0">หมวดหมู่คละสินค้า</h5>
                </div>
              </div>
            </template>
            <div class="row">
              <div class="col-6">
                <select
                  class="form-control"
                  v-model="CategoryAssorted.categorySelect"
                >
                  <option value="" disabled selected>--เลือกหมวดหมู่--</option>
                  <option
                    :value="item.name"
                    v-for="(item, index) in categoryData"
                    :key="index"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
              <div class="col-6 d-flex justify-content-center">
                <button
                  class="btn btn-base btn-primary w-75"
                  @click="onAddAssortedCategory"
                >
                  เพิ่ม
                </button>
              </div>
            </div>
            <div class="row justify-content-end mt-3">
              <div class="form-group">
                <input
                  type="search"
                  v-model="CategoryAssorted.searchQuery"
                  id="search"
                  class="form-control"
                  placeholder="ค้นหา"
                />
              </div>
            </div>
            <div class="row">
              <div
                class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <div class="form-group">
                  <label for="selectshow" class="form-control-label"
                    >แสดง</label
                  >
                  <el-select
                    class="select-primary pagination-select mx-2"
                    v-model="CategoryAssorted.pagination.perPage"
                    placeholder="Per page"
                    style="width: 4.5rem"
                    id="selectshow"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in CategoryAssorted.pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                  <label for="selectshow" class="form-control-label"
                    >รายการ</label
                  >
                </div>
                <base-pagination
                  class="pagination-no-border"
                  v-model="CategoryAssorted.pagination.currentPage"
                  :per-page="CategoryAssorted.pagination.perPage"
                  :total="totalCategoryAssorted"
                >
                </base-pagination>
              </div>
            </div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-table
                v-loading="CategoryAssorted.loading"
                :data="queriedDataCategoryAssorted"
                header-row-class-name="thead-light"
              >
                <el-table-column
                  min-width="170px"
                  align="center"
                  label="ชื่อหมวดหมู่"
                >
                  <template v-slot:default="props">
                    <div class="font-weight-bold">
                      {{ props.row.name }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column min-width="130px" align="center" label="ลบ">
                  <template v-slot:default="props">
                    <button
                      class="btn base-button btn-sm btn-danger rounded-circle"
                      @click="onDeleteAssortedCategory(props.row.id)"
                    >
                      <i class="fa fa-trash-alt"></i>
                    </button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <template v-slot:footer>
              <div
                class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <div class="">
                  <p class="card-category">
                    แสดง {{ fromCategoryAssorted + 1 }} ถึง
                    {{ toCategoryAssorted }} จากทั้งหมด
                    {{ totalCategoryAssorted }} รายการ
                  </p>
                </div>
              </div>
            </template>
          </card>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-6">
          <!--  -->
          <!-- MultipleCard Category -->
          <!--  -->
          <card header-classes="bg-transparent">
            <template v-slot:header>
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="h3 mb-0">ส่วนลดหมวดหมู่</h5>
                </div>
              </div>
            </template>
            <div class="row">
              <div class="col-12">
                <select
                  class="form-control"
                  v-model="CategoryMultiple.categorySelect"
                >
                  <option value="" disabled selected>--เลือกหมวดหมู่--</option>
                  <option
                    :value="item.name"
                    v-for="(item, index) in categoryData"
                    :key="index"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-4">
                <label class="form-control-label">จำนวนเริ่มต้น</label>
                <input
                  v-model="CategoryMultiple.start"
                  type="number"
                  class="form-control"
                />
              </div>
              <div class="col-4">
                <label class="form-control-label">จำนวนสิ้นสุด</label>
                <input
                  v-model="CategoryMultiple.end"
                  type="number"
                  class="form-control"
                />
              </div>
              <div class="col-4">
                <label class="form-control-label"
                  >ลด <span class="text-xs">(ส่วนลดต่อชิ้น)</span></label
                >
                <input
                  v-model="CategoryMultiple.discount"
                  type="number"
                  class="form-control"
                />
              </div>
            </div>
            <div class="row w-100 mt-3 justify-content-center">
              <button
                class="btn btn-base btn-primary w-75"
                @click="onAddMultipleCategory"
              >
                เพิ่ม
              </button>
            </div>
            <div class="row justify-content-end mt-3">
              <div class="form-group">
                <input
                  type="search"
                  v-model="CategoryMultiple.searchQuery"
                  id="search"
                  class="form-control"
                  placeholder="ค้นหา"
                />
              </div>
            </div>
            <div class="row">
              <div
                class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <div class="form-group">
                  <label for="selectshow" class="form-control-label"
                    >แสดง</label
                  >
                  <el-select
                    class="select-primary pagination-select mx-2"
                    v-model="CategoryMultiple.pagination.perPage"
                    placeholder="Per page"
                    style="width: 4.5rem"
                    id="selectshow"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in CategoryMultiple.pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                  <label for="selectshow" class="form-control-label"
                    >รายการ</label
                  >
                </div>
                <base-pagination
                  class="pagination-no-border"
                  v-model="CategoryMultiple.pagination.currentPage"
                  :per-page="CategoryMultiple.pagination.perPage"
                  :total="totalCategoryMultiple"
                >
                </base-pagination>
              </div>
            </div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-table
                v-loading="CategoryMultiple.loading"
                :data="queriedDataCategoryMultiple"
                header-row-class-name="thead-light"
              >
                <el-table-column
                  min-width="170px"
                  align="center"
                  label="ชื่อหมวดหมู่"
                >
                  <template v-slot:default="props">
                    <div class="font-weight-bold">
                      {{ props.row.name }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  min-width="120px"
                  align="center"
                  label="จำนวนเริ่มต้น"
                >
                  <template v-slot:default="props">
                    <div class="font-weight-bold">
                      {{ props.row.start }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  min-width="120px"
                  align="center"
                  label="จำนวนสิ้นสุด"
                >
                  <template v-slot:default="props">
                    <div class="font-weight-bold">
                      {{ props.row.end }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column min-width="100px" align="center" label="ลด">
                  <template v-slot:default="props">
                    <div class="font-weight-bold">
                      {{ props.row.discount }} บาท
                    </div>
                  </template>
                </el-table-column>
                <el-table-column min-width="130px" align="center" label="แก้ไข/ลบ">
                  <template v-slot:default="props">
                    <button
                      class="btn base-button btn-sm btn-primary rounded-circle"
                      @click="openEditMultipleCateModal(props.$index)"
                    >
                      <i class="fa fa-edit"></i>
                    </button>
                    <button
                      class="btn base-button btn-sm btn-danger rounded-circle"
                      @click="onDeleteMultipleCategory(props.row.id)"
                    >
                      <i class="fa fa-trash-alt"></i>
                    </button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <template v-slot:footer>
              <div
                class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <div class="">
                  <p class="card-category">
                    แสดง {{ fromCategoryMultiple + 1 }} ถึง
                    {{ toCategoryMultiple }} จากทั้งหมด
                    {{ totalCategoryMultiple }} รายการ
                  </p>
                </div>
              </div>
            </template>
          </card>
        </div>
        <div class="col-xl-6">
          <!--  -->
          <!-- Pair Product -->
          <!--  -->
          <card header-classes="bg-transparent">
            <template v-slot:header>
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="h3 mb-0">รายการซื้อคู่</h5>
                </div>
              </div>
            </template>
            <div class="row mt-3 w-100">
              <div class="col-6">
                <select
                  class="form-control"
                  v-model="Pair.categorySelectOne"
                  @change="fetchProductByCategoryPairOne"
                >
                  <option value="" disabled selected>--เลือกหมวดหมู่--</option>
                  <option
                    :value="item.name"
                    v-for="(item, index) in categoryData"
                    :key="index"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
              <div class="col-6">
                <select class="form-control" v-model="Pair.productSelectOne">
                  <option value="" disabled selected>--เลือกสินค้า--</option>
                  <option
                    :value="item.name"
                    v-for="(item, index) in Pair.productDataOne"
                    :key="index"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row mt-3 w-100">
              <div class="col-6">
                <select
                  class="form-control"
                  v-model="Pair.categorySelectTwo"
                  @change="fetchProductByCategoryPairTwo"
                >
                  <option value="" disabled selected>--เลือกหมวดหมู่--</option>
                  <option
                    :value="item.name"
                    v-for="(item, index) in categoryData"
                    :key="index"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
              <div class="col-6">
                <select class="form-control" v-model="Pair.productSelectTwo">
                  <option value="" disabled selected>--เลือกสินค้า--</option>
                  <option
                    :value="item.name"
                    v-for="(item, index) in Pair.productDataTwo"
                    :key="index"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row w-100 mt-3">
              <div class="col-6">
                <label class="form-control-label">ข้อความที่โชว์</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="Pair.showname"
                />
              </div>
              <div class="col-6">
                <label class="form-control-label">ราคาที่จะบวกเพิ่ม</label>
                <input
                  type="number"
                  class="form-control"
                  v-model="Pair.price"
                />
              </div>
            </div>
            <div class="row w-100 mt-3 justify-content-center">
              <button class="btn btn-base btn-primary w-75" @click="onAddPair">
                เพิ่ม
              </button>
            </div>
            <div class="row justify-content-end mt-3">
              <div class="form-group">
                <input
                  type="search"
                  v-model="Pair.searchQuery"
                  id="search"
                  class="form-control"
                  placeholder="ค้นหา"
                />
              </div>
            </div>
            <div class="row">
              <div
                class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <div class="form-group">
                  <label for="selectshow" class="form-control-label"
                    >แสดง</label
                  >
                  <el-select
                    class="select-primary pagination-select mx-2"
                    v-model="Pair.pagination.perPage"
                    placeholder="Per page"
                    style="width: 4.5rem"
                    id="selectshow"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in Pair.pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                  <label for="selectshow" class="form-control-label"
                    >รายการ</label
                  >
                </div>
                <base-pagination
                  class="pagination-no-border"
                  v-model="Pair.pagination.currentPage"
                  :per-page="Pair.pagination.perPage"
                  :total="totalPair"
                >
                </base-pagination>
              </div>
            </div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-table
                v-loading="Pair.loading"
                :data="queriedDataPair"
                header-row-class-name="thead-light"
              >
                <el-table-column
                  min-width="200px"
                  align="center"
                  label="ชื่อสินค้า"
                >
                  <template v-slot:default="props">
                    <div class="font-weight-bold">
                      {{ props.row.product_name }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  min-width="200px"
                  align="center"
                  label="ซื้อคู่"
                >
                  <template v-slot:default="props">
                    <div class="font-weight-bold">
                      {{ props.row.pair_name }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column min-width="120px" align="center" label="ราคา">
                  <template v-slot:default="props">
                    <div class="font-weight-bold">
                      {{ props.row.pair_price }} บาท
                    </div>
                  </template>
                </el-table-column>
                <el-table-column min-width="120px" align="center" label="ลบ">
                  <template v-slot:default="props">
                    <button
                      class="btn base-button btn-sm btn-danger rounded-circle"
                      @click="onDeletePair(props.row.product_name)"
                    >
                      <i class="fa fa-trash-alt"></i>
                    </button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <template v-slot:footer>
              <div
                class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <div class="">
                  <p class="card-category">
                    แสดง {{ fromPair + 1 }} ถึง {{ toPair }} จากทั้งหมด
                    {{ totalPair }} รายการ
                  </p>
                </div>
              </div>
            </template>
          </card>
        </div>
      </div>
    </div>
    <div>
      <modal v-model:show="discountModal.show" size="sm">
        <card
          type="secondary"
          header-classes="bg-transparent border-0"
          class="border-0 mb-0"
        >
          <template v-slot:header>
            <div class="text-center">
              <h3 class="modal-title">แก้ไข</h3>
            </div>
          </template>
          <div class="row justify-content-center">
            <h3>{{ discountModal.text }}</h3>
          </div>
          <div class="row">
            <div class="col-12">
              <label class="form-control-label">จำนวนเริ่มต้น</label>
              <input
                v-model="discountModal.start"
                type="number"
                class="form-control"
              />
            </div>
            <div class="col-12">
              <label class="form-control-label">จำนวนสิ้นสุด</label>
              <input
                v-model="discountModal.end"
                type="number"
                class="form-control"
              />
            </div>
            <div class="col-12">
              <label class="form-control-label"
                >ลด <span class="text-xs">(ส่วนลดต่อชิ้น)</span></label
              >
              <input
                v-model="discountModal.discount"
                type="number"
                class="form-control"
              />
            </div>
          </div>
          <div class="text-right mt-3">
            <button type="submit" class="btn btn-primary" @click="onSubmitEdit">
              ยืนยัน
            </button>
            <base-button type="danger" @click="discountModal.show = false"
              >ปิด</base-button
            >
          </div>
        </card>
      </modal>
      <modal v-model:show="multipleCateModal.show" size="sm">
        <card
          type="secondary"
          header-classes="bg-transparent border-0"
          class="border-0 mb-0"
        >
          <template v-slot:header>
            <div class="text-center">
              <h3 class="modal-title">แก้ไข</h3>
            </div>
          </template>
          <div class="row justify-content-center">
            <h3>{{ multipleCateModal.text }}</h3>
          </div>
          <div class="row">
            <div class="col-12">
              <label class="form-control-label">จำนวนเริ่มต้น</label>
              <input
                v-model="multipleCateModal.start"
                type="number"
                class="form-control"
              />
            </div>
            <div class="col-12">
              <label class="form-control-label">จำนวนสิ้นสุด</label>
              <input
                v-model="multipleCateModal.end"
                type="number"
                class="form-control"
              />
            </div>
            <div class="col-12">
              <label class="form-control-label"
                >ลด <span class="text-xs">(ส่วนลดต่อชิ้น)</span></label
              >
              <input
                v-model="multipleCateModal.discount"
                type="number"
                class="form-control"
              />
            </div>
          </div>
          <div class="text-right mt-3">
            <button type="submit" class="btn btn-primary" @click="onSubmitMultipleCateEdit">
              ยืนยัน
            </button>
            <base-button type="danger" @click="multipleCateModal.show = false"
              >ปิด</base-button
            >
          </div>
        </card>
      </modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "../services/axios";
import { useToast } from "vue-toastification";
import "sweetalert2/dist/sweetalert2.css";
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import BasePagination from "@/components/BasePagination";
import modal from "../components/Modal.vue";

const toast = useToast();

export default {
  name: "PromotionPage",
  components: {
    BasePagination,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
    modal,
  },
  data() {
    return {
      categoryData: [],
      multipleProduct: {
        start: 0,
        end: 0,
        discount: 0,
        productData: [],
        categorySelect: "",
        productSelect: "",
        searchQuery: "",
        propsToSearch: ["name", "start", "end", "discount"],
        tableData: [],
        searchedData: [],
        pagination: {
          perPage: 10,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50],
          total: 0,
        },
        loading: false,
      },
      CategoryAssorted: {
        categorySelect: "",
        tableData: [],
        searchQuery: "",
        propsToSearch: ["name"],
        searchedData: [],
        pagination: {
          perPage: 10,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50],
          total: 0,
        },
      },
      CategoryMultiple: {
        start: 0,
        end: 0,
        discount: 0,
        categorySelect: "",
        tableData: [],
        searchQuery: "",
        propsToSearch: ["name", "start", "end", "discount"],
        searchedData: [],
        pagination: {
          perPage: 10,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50],
          total: 0,
        },
      },
      Pair: {
        productDataOne: [],
        productDataTwo: [],
        categorySelectOne: "",
        productSelectOne: "",
        categorySelectTwo: "",
        productSelectTwo: "",
        price: 0,
        showname: "",
        searchQuery: "",
        propsToSearch: ["product_name", "pair_name", "pair_price"],
        tableData: [],
        searchedData: [],
        pagination: {
          perPage: 10,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50],
          total: 0,
        },
        loading: false,
      },
      discountModal: {
        show: false,
        text: "",
        start: 0,
        end: 0,
        discount: 0,
        currId: null,
      },
      multipleCateModal: {
        show: false,
        text: "",
        start: 0,
        end: 0,
        discount: 0,
        currId: null,
      },
    };
  },
  created() {
    this.fetchCategory();
    this.fetchMultiProduct();
    this.fetchPair();
    this.fetchCategoryAssorted();
    this.fetchCategoryMultiple();
  },
  methods: {
    openEditModal(id) {
      this.discountModal.currId = this.queriedDataMultipleProduct[id].id;
      this.discountModal.text = this.queriedDataMultipleProduct[id].name;
      this.discountModal.start = this.queriedDataMultipleProduct[id].start;
      this.discountModal.end = this.queriedDataMultipleProduct[id].end;
      this.discountModal.discount = this.queriedDataMultipleProduct[
        id
      ].discount;
      this.discountModal.show = true;
    },
    openEditMultipleCateModal(id) {
      this.multipleCateModal.currId = this.queriedDataCategoryMultiple[id].id;
      this.multipleCateModal.text = this.queriedDataCategoryMultiple[id].name;
      this.multipleCateModal.start = this.queriedDataCategoryMultiple[id].start;
      this.multipleCateModal.end = this.queriedDataCategoryMultiple[id].end;
      this.multipleCateModal.discount = this.queriedDataCategoryMultiple[
        id
      ].discount;
      this.multipleCateModal.show = true;
    },
    onSubmitEdit() {
      axios
        .post(
          "/admin/product/multidiscount/edit",
          {
            product: this.discountModal.currId,
            start: this.discountModal.start,
            end: this.discountModal.end,
            discount: this.discountModal.discount,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("AUTH"),
            },
          }
        )
        .then((resp) => {
          if (resp?.data?.status === "success") {
            toast.success("แก้ไขสำเร็จ");
            this.discountModal.show = false;
            this.fetchMultiProduct();
          }
        })
        .catch((err) => {
          if (err?.response) {
            if (
              err?.response?.status !== 401 &&
              err?.response?.status !== 403
            ) {
              toast.error(
                err?.response?.data?.message
                  ? err?.response?.data?.message
                  : "ไม่สามารถแก้ไขได้"
              );
            } else {
              this.$store.dispatch("logoutAuth");
              localStorage.removeItem("AUTH");
              this.$router.push("/login");
            }
          }
        });
    },
    onSubmitMultipleCateEdit() {
      axios
        .post(
          "/admin/category/multiple/edit",
          {
            category: this.multipleCateModal.currId,
            start: this.multipleCateModal.start,
            end: this.multipleCateModal.end,
            discount: this.multipleCateModal.discount,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("AUTH"),
            },
          }
        )
        .then((resp) => {
          if (resp?.data?.status === "success") {
            toast.success("แก้ไขสำเร็จ");
            this.multipleCateModal.show = false;
            this.fetchCategoryMultiple();
          }
        })
        .catch((err) => {
          if (err?.response) {
            if (
              err?.response?.status !== 401 &&
              err?.response?.status !== 403
            ) {
              toast.error(
                err?.response?.data?.message
                  ? err?.response?.data?.message
                  : "ไม่สามารถแก้ไขได้"
              );
            } else {
              this.$store.dispatch("logoutAuth");
              localStorage.removeItem("AUTH");
              this.$router.push("/login");
            }
          }
        });
    },
    fetchCategory() {
      axios
        .get("/admin/category", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("AUTH"),
          },
        })
        .then((resp) => {
          if (resp?.data?.status === "success") {
            this.categoryData = resp?.data?.data;
          }
        })
        .catch((err) => {
          if (err?.response) {
            if (
              err?.response?.status !== 401 &&
              err?.response?.status !== 403
            ) {
              toast.error(
                err?.response?.data?.message
                  ? err?.response?.data?.message
                  : "ไม่สามารถโหลดข้อมูลหมวดหมู่ได้"
              );
            } else {
              this.$store.dispatch("logoutAuth");
              localStorage.removeItem("AUTH");
              this.$router.push("/login");
            }
          }
        });
    },
    fetchCategoryAssorted() {
      axios
        .get("/admin/category/assorted", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("AUTH"),
          },
        })
        .then((resp) => {
          if (resp?.data?.status === "success") {
            this.CategoryAssorted.tableData = resp?.data?.data;
          }
        })
        .catch((err) => {
          if (err?.response) {
            if (
              err?.response?.status !== 401 &&
              err?.response?.status !== 403
            ) {
              toast.error(
                err?.response?.data?.message
                  ? err?.response?.data?.message
                  : "ไม่สามารถโหลดข้อมูลได้"
              );
            } else {
              this.$store.dispatch("logoutAuth");
              localStorage.removeItem("AUTH");
              this.$router.push("/login");
            }
          }
        });
    },
    fetchCategoryMultiple() {
      axios
        .get("/admin/category/multiple", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("AUTH"),
          },
        })
        .then((resp) => {
          if (resp?.data?.status === "success") {
            this.CategoryMultiple.tableData = resp?.data?.data;
          }
        })
        .catch((err) => {
          if (err?.response) {
            if (
              err?.response?.status !== 401 &&
              err?.response?.status !== 403
            ) {
              toast.error(
                err?.response?.data?.message
                  ? err?.response?.data?.message
                  : "ไม่สามารถโหลดข้อมูลได้"
              );
            } else {
              this.$store.dispatch("logoutAuth");
              localStorage.removeItem("AUTH");
              this.$router.push("/login");
            }
          }
        });
    },
    fetchProductByCategory() {
      if (this.multipleProduct.categorySelect) {
        axios
          .post(
            "/admin/product/category",
            {
              category: this.multipleProduct.categorySelect,
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("AUTH"),
              },
            }
          )
          .then((resp) => {
            if (resp?.data?.status === "success") {
              this.multipleProduct.productData = resp?.data?.data;
            }
          })
          .catch((err) => {
            if (err?.response) {
              if (
                err?.response?.status !== 401 &&
                err?.response?.status !== 403
              ) {
                toast.error(
                  err?.response?.data?.message
                    ? err?.response?.data?.message
                    : "ไม่สามารถโหลดข้อมูลหมวดหมู่ได้"
                );
              } else {
                this.$store.dispatch("logoutAuth");
                localStorage.removeItem("AUTH");
                this.$router.push("/login");
              }
            }
          });
      }
    },
    fetchProductByCategoryPairOne() {
      if (this.Pair.categorySelectOne) {
        axios
          .post(
            "/admin/product/category",
            {
              category: this.Pair.categorySelectOne,
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("AUTH"),
              },
            }
          )
          .then((resp) => {
            if (resp?.data?.status === "success") {
              this.Pair.productDataOne = resp?.data?.data;
            }
          })
          .catch((err) => {
            if (err?.response) {
              if (
                err?.response?.status !== 401 &&
                err?.response?.status !== 403
              ) {
                toast.error(
                  err?.response?.data?.message
                    ? err?.response?.data?.message
                    : "ไม่สามารถโหลดข้อมูลหมวดหมู่ได้"
                );
              } else {
                this.$store.dispatch("logoutAuth");
                localStorage.removeItem("AUTH");
                this.$router.push("/login");
              }
            }
          });
      }
    },
    fetchProductByCategoryPairTwo() {
      if (this.Pair.categorySelectTwo) {
        axios
          .post(
            "/admin/product/category",
            {
              category: this.Pair.categorySelectTwo,
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("AUTH"),
              },
            }
          )
          .then((resp) => {
            if (resp?.data?.status === "success") {
              this.Pair.productDataTwo = resp?.data?.data;
            }
          })
          .catch((err) => {
            if (err?.response) {
              if (
                err?.response?.status !== 401 &&
                err?.response?.status !== 403
              ) {
                toast.error(
                  err?.response?.data?.message
                    ? err?.response?.data?.message
                    : "ไม่สามารถโหลดข้อมูลหมวดหมู่ได้"
                );
              } else {
                this.$store.dispatch("logoutAuth");
                localStorage.removeItem("AUTH");
                this.$router.push("/login");
              }
            }
          });
      }
    },
    onAddPair() {
      if (
        this.Pair.productSelectOne &&
        this.Pair.productSelectOne &&
        this.Pair.showname
      ) {
        axios
          .post(
            "/admin/promotionpair/add",
            {
              showname: this.Pair.productSelectTwo ? this.Pair.showname : "",
              product: this.Pair.productSelectOne,
              pairName: this.Pair.productSelectTwo
                ? this.Pair.productSelectTwo
                : this.Pair.showname,
              price: this.Pair.price,
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("AUTH"),
              },
            }
          )
          .then((resp) => {
            if (resp?.data?.status === "success") {
              this.fetchPair();
              toast.success("สำเร็จ");
              this.Pair.categorySelectOne = "";
              this.Pair.categorySelectTwo = "";
              this.Pair.productSelectOne = "";
              this.Pair.productSelectTwo = "";
              this.Pair.price = "";
              this.Pair.showname = "";
            }
          })
          .catch((err) => {
            if (err?.response) {
              if (
                err?.response?.status !== 401 &&
                err?.response?.status !== 403
              ) {
                toast.error(
                  err?.response?.data?.message
                    ? err?.response?.data?.message
                    : "ไม่สามารถโหลดข้อมูลหมวดหมู่ได้"
                );
              } else {
                this.$store.dispatch("logoutAuth");
                localStorage.removeItem("AUTH");
                this.$router.push("/login");
              }
            }
          });
      } else {
        toast.error("กรุณากรอกข้อมูล");
      }
    },
    fetchPair() {
      this.Pair.loading = true;
      axios
        .get("/admin/promotionpair/", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("AUTH"),
          },
        })
        .then((resp) => {
          this.Pair.loading = false;
          if (resp?.data?.status === "success") {
            this.Pair.tableData = resp?.data?.data;
          }
        })
        .catch((err) => {
          this.Pair.loading = false;
          if (err?.response) {
            if (
              err?.response?.status !== 401 &&
              err?.response?.status !== 403
            ) {
              toast.error(
                err?.response?.data?.message
                  ? err?.response?.data?.message
                  : "ไม่สามารถโหลดข้อมูลรายการซื้อคู่ได้"
              );
            } else {
              this.$store.dispatch("logoutAuth");
              localStorage.removeItem("AUTH");
              this.$router.push("/login");
            }
          }
        });
    },
    onDeletePair(product) {
      axios
        .post(
          "/admin/promotionpair/remove",
          {
            product: product,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("AUTH"),
            },
          }
        )
        .then((resp) => {
          if (resp?.data?.status === "success") {
            toast.success("ลบข้อมูลสำเร็จ");
            this.fetchPair();
          }
        })
        .catch((err) => {
          if (err?.response) {
            if (
              err?.response?.status !== 401 &&
              err?.response?.status !== 403
            ) {
              toast.error(
                err?.response?.data?.message
                  ? err?.response?.data?.message
                  : "ไม่สามารถโหลดลบข้อมูลได้"
              );
            } else {
              this.$store.dispatch("logoutAuth");
              localStorage.removeItem("AUTH");
              this.$router.push("/login");
            }
          }
        });
    },
    fetchMultiProduct() {
      this.multipleProduct.loading = true;
      let curPage = this.multipleProduct.pagination.currentPage;
      axios
        .get("/admin/product/multidiscount", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("AUTH"),
          },
        })
        .then((resp) => {
          this.multipleProduct.loading = false;
          if (resp?.data?.status === "success") {
            this.multipleProduct.tableData = resp?.data?.data;
            
            setTimeout(() => {
              if (this.totalMultipleProduct / this.multipleProduct.pagination.perPage < curPage) {
                this.multipleProduct.pagination.currentPage = Math.ceil(this.totalMultipleProduct / this.multipleProduct.pagination.perPage)
              } else {
                this.multipleProduct.pagination.currentPage = curPage;
              }
            }, 10)
          }
        })
        .catch((err) => {
          this.multipleProduct.loading = false;
          if (err?.response) {
            if (
              err?.response?.status !== 401 &&
              err?.response?.status !== 403
            ) {
              toast.error(
                err?.response?.data?.message
                  ? err?.response?.data?.message
                  : "ไม่สามารถโหลดข้อมูลส่วนลดซื้อสินค้าหลายชิ้นได้"
              );
            } else {
              this.$store.dispatch("logoutAuth");
              localStorage.removeItem("AUTH");
              this.$router.push("/login");
            }
          }
        });
    },
    onDeleteMultipleProduct(id) {
      axios
        .post(
          "/admin/product/multidiscount/remove",
          {
            product: id,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("AUTH"),
            },
          }
        )
        .then((resp) => {
          if (resp?.data?.status === "success") {
            toast.success("ลบข้อมูลสำเร็จ");
            this.fetchMultiProduct();
          }
        })
        .catch((err) => {
          if (err?.response) {
            if (
              err?.response?.status !== 401 &&
              err?.response?.status !== 403
            ) {
              toast.error(
                err?.response?.data?.message
                  ? err?.response?.data?.message
                  : "ไม่สามารถโหลดลบข้อมูลได้"
              );
            } else {
              this.$store.dispatch("logoutAuth");
              localStorage.removeItem("AUTH");
              this.$router.push("/login");
            }
          }
        });
    },
    onDeleteAssortedCategory(id) {
      axios
        .post(
          "/admin/category/assorted/remove",
          {
            category: id,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("AUTH"),
            },
          }
        )
        .then((resp) => {
          if (resp?.data?.status === "success") {
            toast.success("ลบข้อมูลสำเร็จ");
            this.fetchCategoryAssorted();
          }
        })
        .catch((err) => {
          if (err?.response) {
            if (
              err?.response?.status !== 401 &&
              err?.response?.status !== 403
            ) {
              toast.error(
                err?.response?.data?.message
                  ? err?.response?.data?.message
                  : "ไม่สามารถโหลดลบข้อมูลได้"
              );
            } else {
              this.$store.dispatch("logoutAuth");
              localStorage.removeItem("AUTH");
              this.$router.push("/login");
            }
          }
        });
    },
    onDeleteMultipleCategory(id) {
      axios
        .post(
          "/admin/category/multiple/remove",
          {
            category: id,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("AUTH"),
            },
          }
        )
        .then((resp) => {
          if (resp?.data?.status === "success") {
            toast.success("ลบข้อมูลสำเร็จ");
            this.fetchCategoryMultiple();
          }
        })
        .catch((err) => {
          if (err?.response) {
            if (
              err?.response?.status !== 401 &&
              err?.response?.status !== 403
            ) {
              toast.error(
                err?.response?.data?.message
                  ? err?.response?.data?.message
                  : "ไม่สามารถโหลดลบข้อมูลได้"
              );
            } else {
              this.$store.dispatch("logoutAuth");
              localStorage.removeItem("AUTH");
              this.$router.push("/login");
            }
          }
        });
    },
    onAddMultiProduct() {
      if (
        this.multipleProduct.productSelect &&
        this.multipleProduct.start &&
        this.multipleProduct.end &&
        this.multipleProduct.discount
      ) {
        axios
          .post(
            "/admin/product/multidiscount/add",
            {
              product: this.multipleProduct.productSelect,
              start: this.multipleProduct.start,
              end: this.multipleProduct.end,
              discount: this.multipleProduct.discount,
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("AUTH"),
              },
            }
          )
          .then((resp) => {
            if (resp?.data?.status === "success") {
              toast.success("เพิ่มข้อมูลสำเร็จ");
              this.multipleProduct.start = 0;
              this.multipleProduct.end = 0;
              this.multipleProduct.discount = 0;
              this.fetchMultiProduct();
            }
          })
          .catch((err) => {
            if (err?.response) {
              if (
                err?.response?.status !== 401 &&
                err?.response?.status !== 403
              ) {
                toast.error(
                  err?.response?.data?.message
                    ? err?.response?.data?.message
                    : "ไม่สามารถเพิ่มข้อมูลได้"
                );
              } else {
                this.$store.dispatch("logoutAuth");
                localStorage.removeItem("AUTH");
                this.$router.push("/login");
              }
            }
          });
      } else {
        toast.error("กรุณากรอกข้อมูล");
      }
    },
    onAddAssortedCategory() {
      if (this.CategoryAssorted.categorySelect) {
        axios
          .post(
            "/admin/category/assorted/add",
            {
              category: this.CategoryAssorted.categorySelect,
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("AUTH"),
              },
            }
          )
          .then((resp) => {
            if (resp?.data?.status === "success") {
              toast.success("เพิ่มข้อมูลสำเร็จ");
              this.fetchCategoryAssorted();
            }
          })
          .catch((err) => {
            if (err?.response) {
              if (
                err?.response?.status !== 401 &&
                err?.response?.status !== 403
              ) {
                toast.error(
                  err?.response?.data?.message
                    ? err?.response?.data?.message
                    : "ไม่สามารถเพิ่มข้อมูลได้"
                );
              } else {
                this.$store.dispatch("logoutAuth");
                localStorage.removeItem("AUTH");
                this.$router.push("/login");
              }
            }
          });
      } else {
        toast.error("กรุณากรอกข้อมูล");
      }
    },
    onAddMultipleCategory() {
      if (
        this.CategoryMultiple.categorySelect &&
        this.CategoryMultiple.start &&
        this.CategoryMultiple.end &&
        this.CategoryMultiple.discount
      ) {
        axios
          .post(
            "/admin/category/multiple/add",
            {
              category: this.CategoryMultiple.categorySelect,
              start: this.CategoryMultiple.start,
              end: this.CategoryMultiple.end,
              discount: this.CategoryMultiple.discount,
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("AUTH"),
              },
            }
          )
          .then((resp) => {
            if (resp?.data?.status === "success") {
              toast.success("เพิ่มข้อมูลสำเร็จ");
              this.fetchCategoryMultiple();
            }
          })
          .catch((err) => {
            if (err?.response) {
              if (
                err?.response?.status !== 401 &&
                err?.response?.status !== 403
              ) {
                toast.error(
                  err?.response?.data?.message
                    ? err?.response?.data?.message
                    : "ไม่สามารถเพิ่มข้อมูลได้"
                );
              } else {
                this.$store.dispatch("logoutAuth");
                localStorage.removeItem("AUTH");
                this.$router.push("/login");
              }
            }
          });
      } else {
        toast.error("กรุณากรอกข้อมูล");
      }
    },
  },
  computed: {
    ...mapGetters(["IsLoggedIn"]),
    //
    // MultipleProduct
    //
    pagedDataMultipleProduct() {
      return this.multipleProduct.tableData.slice(
        this.fromMultipleProduct,
        this.toMultipleProduct
      );
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedDataMultipleProduct}
     */
    queriedDataMultipleProduct() {
      if (!this.multipleProduct.searchQuery) {
        return this.pagedDataMultipleProduct;
      }
      let result = this.multipleProduct.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.multipleProduct.propsToSearch) {
          let rowValue = row[key].toString().toLowerCase();
          if (
            rowValue.includes &&
            rowValue.includes(this.multipleProduct.searchQuery.toLowerCase())
          ) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.fromMultipleProduct, this.toMultipleProduct);
    },
    toMultipleProduct() {
      let highBound =
        this.fromMultipleProduct + this.multipleProduct.pagination.perPage;
      if (this.totalMultipleProduct < highBound) {
        highBound = this.totalMultipleProduct;
      }
      return highBound;
    },
    fromMultipleProduct() {
      return (
        this.multipleProduct.pagination.perPage *
        (this.multipleProduct.pagination.currentPage - 1)
      );
    },
    totalMultipleProduct() {
      return this.multipleProduct.searchedData.length > 0
        ? this.multipleProduct.searchedData.length
        : this.multipleProduct.tableData.length;
    },
    //
    // AssortedCategory
    //
    pagedDataCategoryAssorted() {
      return this.CategoryAssorted.tableData.slice(
        this.fromCategoryAssorted,
        this.toCategoryAssorted
      );
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedDataCategoryAssorted}
     */
    queriedDataCategoryAssorted() {
      if (!this.CategoryAssorted.searchQuery) {
        return this.pagedDataCategoryAssorted;
      }
      let result = this.CategoryAssorted.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.CategoryAssorted.propsToSearch) {
          let rowValue = row[key].toString().toLowerCase();
          if (
            rowValue.includes &&
            rowValue.includes(this.CategoryAssorted.searchQuery.toLowerCase())
          ) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.fromCategoryAssorted, this.toCategoryAssorted);
    },
    toCategoryAssorted() {
      let highBound =
        this.fromCategoryAssorted + this.CategoryAssorted.pagination.perPage;
      if (this.totalCategoryAssorted < highBound) {
        highBound = this.totalCategoryAssorted;
      }
      return highBound;
    },
    fromCategoryAssorted() {
      return (
        this.CategoryAssorted.pagination.perPage *
        (this.CategoryAssorted.pagination.currentPage - 1)
      );
    },
    totalCategoryAssorted() {
      return this.CategoryAssorted.searchedData.length > 0
        ? this.CategoryAssorted.searchedData.length
        : this.CategoryAssorted.tableData.length;
    },
    //
    // MultipleCategory
    //
    pagedDataCategoryMultiple() {
      return this.CategoryMultiple.tableData.slice(
        this.fromCategoryMultiple,
        this.toCategoryMultiple
      );
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedDataCategoryMultiple}
     */
    queriedDataCategoryMultiple() {
      if (!this.CategoryMultiple.searchQuery) {
        return this.pagedDataCategoryMultiple;
      }
      let result = this.CategoryMultiple.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.CategoryMultiple.propsToSearch) {
          let rowValue = row[key].toString().toLowerCase();
          if (
            rowValue.includes &&
            rowValue.includes(this.CategoryMultiple.searchQuery.toLowerCase())
          ) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.fromCategoryMultiple, this.toCategoryMultiple);
    },
    toCategoryMultiple() {
      let highBound =
        this.fromCategoryMultiple + this.CategoryMultiple.pagination.perPage;
      if (this.totalCategoryMultiple < highBound) {
        highBound = this.totalCategoryMultiple;
      }
      return highBound;
    },
    fromCategoryMultiple() {
      return (
        this.CategoryMultiple.pagination.perPage *
        (this.CategoryMultiple.pagination.currentPage - 1)
      );
    },
    totalCategoryMultiple() {
      return this.CategoryMultiple.searchedData.length > 0
        ? this.CategoryMultiple.searchedData.length
        : this.CategoryMultiple.tableData.length;
    },
    //
    // PairProduct
    //
    pagedDataPair() {
      return this.Pair.tableData.slice(this.fromPair, this.toPair);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedDataPair}
     */
    queriedDataPair() {
      if (!this.Pair.searchQuery) {
        return this.pagedDataPair;
      }
      let result = this.Pair.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.Pair.propsToSearch) {
          let rowValue = row[key].toString().toLowerCase();
          if (
            rowValue.includes &&
            rowValue.includes(this.Pair.searchQuery.toLowerCase())
          ) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.fromPair, this.toPair);
    },
    toPair() {
      let highBound = this.fromPair + this.Pair.pagination.perPage;
      if (this.totalPair < highBound) {
        highBound = this.totalPair;
      }
      return highBound;
    },
    fromPair() {
      return (
        this.Pair.pagination.perPage * (this.Pair.pagination.currentPage - 1)
      );
    },
    totalPair() {
      return this.Pair.searchedData.length > 0
        ? this.Pair.searchedData.length
        : this.Pair.tableData.length;
    },
  },
};
</script>

<style>
.el-table .el-table__header-wrapper thead th .cell {
  justify-content: center;
}
</style>
