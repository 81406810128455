<template>
    <div>
      <base-header class="pb-6">
        <div class="row align-items-center py-4">
          <div class="col-lg-6 col-7">
            <h6 class="h2 text-white d-inline-block mb-0">ข้อความเวลาเปิด / ปิด</h6>
          </div>
        </div>
      </base-header>
      <div class="container-fluid mt--6">
        <div class="row">
          <div class="col-12">
            <!-- MultipleCard -->
            <card header-classes="bg-transparent">
              <template v-slot:header>
                <div class="row align-items-center">
                  <div class="col">
                    <h5 class="h3 mb-0"></h5>
                  </div>
                </div>
              </template>
              <div class="row text-center">
                <div class="col-12 col-md-4"></div>
                <div class="col-12 col-md-4">
                  <div class="form-group">
                    <label class="font-weight-bold">ข้อความ</label>
                    <textarea rows="10" v-model="text" class="form-control"></textarea>
                  </div>
                  <button class="btn btn-primary" @click="submitText">ยืนยัน</button>
                </div>
                <div class="col-12 col-md-4"></div>
              </div>
            </card>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  import { useToast } from "vue-toastification";
  import axios from "../services/axios";
  
  const toast = useToast();
  
  export default {
    name: "TimeSettingPage",
    components: {},
    data() {
      return {
        text: "",
      };
    },
    created() {
      if (!this.IsLoggedIn) {
        this.$router.push("/login");
      } else {
        this.fetchTimeSetting();
      }
    },
    methods: {
        fetchTimeSetting() {
            axios.get("/timesetting").then((resp) => {
                this.text = resp.data.data[0].text
            }).catch((err) => {
                console.log(err)
            })
        },
        submitText() {
            axios.post("/admin/boardcast/timesetting", {
                text: this.text,
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("AUTH"),
              },
            }).then(() => {
                toast.success("สำเร็จ");
            }).catch(() => {
                toast.error("ไม่สามารถตั้งค่าได้");
            })
        },
    },
    computed: {
      ...mapGetters(["IsLoggedIn"]),
    },
  };
  </script>
  
  <style></style>
  