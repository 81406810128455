<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0"></h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-12">
          <!-- MultipleCard -->
          <card header-classes="bg-transparent">
            <template v-slot:header>
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="h3 mb-0">ประวัติการแก้ไขจำนวนสินค้า</h5>
                </div>
              </div>
            </template>
            <div class="row mt-4">
              <div
                class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <div class="form-group">
                  <label for="selectshow" class="form-control-label"
                    >แสดง</label
                  >
                  <el-select
                    class="select-primary pagination-select mx-2"
                    v-model="pagination.perPage"
                    placeholder="Per page"
                    style="width: 4.5rem"
                    id="selectshow"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                  <label for="selectshow" class="form-control-label"
                    >รายการ</label
                  >
                </div>
                <base-pagination
                  class="pagination-no-border"
                  v-model="pagination.currentPage"
                  :per-page="pagination.perPage"
                  :total="total"
                >
                </base-pagination>
              </div>
            </div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-table
                v-loading="loading"
                :data="queriedData"
                header-row-class-name="thead-light"
              >
                <el-table-column
                  min-width="250px"
                  align="center"
                  label="ชื่อสินค้า"
                >
                  <template v-slot:default="props">
                    <div class="font-weight-bold">
                      {{ props.row.name }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  min-width="250px"
                  align="center"
                  label="วันที่/เวลา"
                >
                  <template v-slot:default="props">
                    <div class="font-weight-bold">
                      {{ props.row.datetime }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  min-width="110px"
                  align="center"
                  label="อ.เมือง"
                >
                  <template v-slot:default="props">
                    <div class="font-weight-bold">
                      {{ props.row.loei }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  min-width="110px"
                  align="center"
                  label="อ.วังสะพุง"
                >
                  <template v-slot:default="props">
                    <div class="font-weight-bold">
                      {{ props.row.wang }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  min-width="110px"
                  align="center"
                  label="อ.เชียงคาน"
                >
                  <template v-slot:default="props">
                    <div class="font-weight-bold">
                      {{ props.row.ck }}
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <template v-slot:footer>
              <div
                class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <div class="">
                  <p class="card-category">
                    แสดง {{ from + 1 }} ถึง {{ to }} จากทั้งหมด
                    {{ total }} รายการ
                  </p>
                </div>
              </div>
            </template>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "../services/axios";
import { useToast } from "vue-toastification";
import "sweetalert2/dist/sweetalert2.css";
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import BasePagination from "@/components/BasePagination";

const toast = useToast();

export default {
  name: "HistoryStockPage",
  components: {
    BasePagination,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
  },
  data() {
    return {
      imagePath: process.env.VUE_APP_IMAGE_PATH,
      searchQuery: "",
      propsToSearch: [],
      tableData: [],
      searchedData: [],
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      loading: false,
    };
  },
  created() {
    if (this.$route.params.product_id) {
      this.fetchData();
    }
  },
  methods: {
    fetchData() {
      this.loading = true;
      axios
        .get(`/admin/historystock?product=${this.$route.params.product_id}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("AUTH")
          }
        })
        .then((resp) => {
          this.loading = false;
          if (resp?.data?.status === "success") {
            this.tableData = resp?.data?.data;
          }
        })
        .catch((err) => {
          this.loading = false;
          if (err?.response) {
            if (
              err?.response?.status !== 401 &&
              err?.response?.status !== 403
            ) {
              toast.error(
                err?.response?.data?.message
                  ? err?.response?.data?.message
                  : "ไม่สามารถโหลดข้อมูลได้"
              );
            } else {
              this.$store.dispatch("logoutAuth");
              localStorage.removeItem("AUTH");
              this.$router.push("/login");
            }
          }
        });
    },
  },
  computed: {
    ...mapGetters(["IsLoggedIn"]),
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString();
          if (rowValue.includes && rowValue.includes(this.searchQuery)) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
};
</script>

<style>
.el-table .el-table__header-wrapper thead th .cell {
  justify-content: center;
}
</style>
