<template>
  <div class="wrapper">
    <!-- <notifications></notifications> -->
    <side-bar>
      <template v-slot:links>
        <sidebar-item
          :link="{
            name: 'สินค้า',
            icon: 'ni ni-cart text-blue',
            path: '/dashboard',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'โปรโมชั่น',
            icon: 'ni ni-book-bookmark text-green',
            path: '/promotion',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'ผู้ดูแล',
            icon: 'ni ni-calendar-grid-58 text-red',
            path: '/admin',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Boardcast',
            icon: 'ni ni-spaceship text-orange',
            path: '/boardcast',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'สรุปยอด',
            icon: 'ni ni-tag text-blue',
            path: '/summary',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'ประวัติสรุปยอด',
            icon: 'ni ni-chart-bar-32 text-red',
            path: '/totalsales',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'ยอดจำนวนสินค้า',
            icon: 'ni ni-basket text-green',
            path: '/sumproduct',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'ประกาศ',
            icon: 'ni ni-bell-55 text-orange',
            path: '/announce',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'เวลาเปิด / ปิด',
            icon: 'ni ni-watch-time text-blue',
            path: '/timesetting',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'เมสเซนเจอร์',
            icon: 'ni ni-button-power text-red',
            path: '/messenger',
          }"
        >
        </sidebar-item>
      </template>
    </side-bar>
    <div class="main-content">
      <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>

      <div @click="$sidebar.displaySidebar(false)">
        <router-view></router-view>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import axios from "../../services/axios";
import { useToast } from "vue-toastification";
import { mapGetters } from "vuex";

const toast = useToast();

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
  },
  created() {
    if (this.IsLoggedIn) {
      this.fetchProfile();
    } else {
      this.$router.push("/login");
    }
  },
  methods: {
    fetchProfile() {
      axios
        .get("/admin/user/profile", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("AUTH"),
          },
        })
        .then((resp) => {
          if (resp?.data?.status === "success") {
            this.$store.dispatch("saveProfile", resp?.data?.data[0]);
          } else {
            this.$store.dispatch("logoutAuth");
            localStorage.removeItem("AUTH");
            this.$router.push("/login");
          }
        })
        .catch((err) => {
          if (err?.response) {
            if (err?.response?.status !== 500) {
              if (
                err?.response?.status !== 401 &&
                err?.response?.status !== 403
              ) {
                if (err?.response?.data?.message === "ไม่พบข้อมูลบัญชีผู้ใช้") {
                  this.$store.dispatch("logoutAuth");
                  localStorage.removeItem("AUTH");
                  this.$router.push("/login");
                } else {
                  toast.error(
                    err?.response?.data?.message
                      ? err?.response?.data?.message
                      : "ไม่สามารถโหลดข้อมูลโปรไฟล์ได้"
                  );
                }
              } else {
                this.$store.dispatch("logoutAuth");
                localStorage.removeItem("AUTH");
                this.$router.push("/login");
              }
            }
          }
        });
    },
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }
    },
  },
  mounted() {
    this.initScrollbar();
  },
  computed: {
    ...mapGetters(["IsLoggedIn"]),
  },
};
</script>
<style lang="scss"></style>
