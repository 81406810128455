<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">จัดการผู้ดูแล</h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-12">
          <!-- MultipleCard -->
          <card header-classes="bg-transparent">
            <template v-slot:header>
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="h3 mb-0">ผู้ดูแล</h5>
                </div>
              </div>
            </template>
            <div class="row">
              <div class="col-4">
                <input
                  type="text"
                  class="form-control"
                  v-model="username"
                  placeholder="Username"
                />
              </div>
              <div class="col-4">
                <input
                  type="password"
                  class="form-control"
                  v-model="password"
                  placeholder="Password"
                />
              </div>
              <div class="col-4">
                <button
                  class="btn btn-base btn-primary w-100"
                  @click="onAddAdmin"
                >
                  เพิ่ม
                </button>
              </div>
            </div>
            <div class="row justify-content-end mt-3">
              <div class="form-group">
                <input
                  type="search"
                  v-model="searchQuery"
                  id="search"
                  class="form-control"
                  placeholder="ค้นหา"
                />
              </div>
            </div>
            <div class="row">
              <div
                class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <div class="form-group">
                  <label for="selectshow" class="form-control-label"
                    >แสดง</label
                  >
                  <el-select
                    class="select-primary pagination-select mx-2"
                    v-model="pagination.perPage"
                    placeholder="Per page"
                    style="width: 4.5rem"
                    id="selectshow"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                  <label for="selectshow" class="form-control-label"
                    >รายการ</label
                  >
                </div>
                <base-pagination
                  class="pagination-no-border"
                  v-model="pagination.currentPage"
                  :per-page="pagination.perPage"
                  :total="total"
                >
                </base-pagination>
              </div>
            </div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-table
                v-loading="loading"
                :data="queriedData"
                header-row-class-name="thead-light"
              >
                <el-table-column
                  min-width="280px"
                  align="center"
                  label="ยูสเซอร์"
                >
                  <template v-slot:default="props">
                    <div class="font-weight-bold">
                      {{ props.row.username }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  min-width="130px"
                  align="center"
                  label="จัดการ"
                >
                  <template v-slot:default="props">
                    <button
                      class="btn base-button btn-sm btn-danger rounded-circle"
                      @click="onDeleteAdmin(props.row.id)"
                    >
                      <i class="fa fa-trash-alt"></i>
                    </button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <template v-slot:footer>
              <div
                class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <div class="">
                  <p class="card-category">
                    แสดง {{ from + 1 }} ถึง {{ to }} จากทั้งหมด
                    {{ total }} รายการ
                  </p>
                </div>
              </div>
            </template>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "../services/axios";
import { useToast } from "vue-toastification";
import "sweetalert2/dist/sweetalert2.css";
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import BasePagination from "@/components/BasePagination";

const toast = useToast();

export default {
  name: "AdminPage",
  components: {
    BasePagination,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
  },
  data() {
    return {
      username: "",
      password: "",
      searchQuery: "",
      propsToSearch: ["username"],
      tableData: [],
      searchedData: [],
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      loading: false,
    };
  },
  created() {
    this.fetchAdminData();
  },
  methods: {
    fetchAdminData() {
      axios
        .get("/admin/user/", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("AUTH"),
          },
        })
        .then((resp) => {
          if (resp?.data?.status === "success") {
            this.tableData = resp?.data?.data;
          }
        })
        .catch((err) => {
          if (err?.response) {
            if (
              err?.response?.status !== 401 &&
              err?.response?.status !== 403
            ) {
              toast.error(
                err?.response?.data?.message
                  ? err?.response?.data?.message
                  : "ไม่สามารถโหลดข้อมูลได้"
              );
            } else {
              this.$store.dispatch("logoutAuth");
              localStorage.removeItem("AUTH");
              this.$router.push("/login");
            }
          }
        });
    },
    onAddAdmin() {
      if (this.username && this.password) {
        axios
          .post(
            "/admin/auth/register",
            {
              username: this.username,
              password: this.password,
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("AUTH"),
              },
            }
          )
          .then((resp) => {
            if (resp?.status === 204) {
              toast.success("สำเร็จ");
              this.fetchAdminData();
            }
          })
          .catch((err) => {
            if (err?.response) {
              if (
                err?.response?.status !== 401 &&
                err?.response?.status !== 403
              ) {
                toast.error(
                  err?.response?.data?.message
                    ? err?.response?.data?.message
                    : "ไม่สามารถเพิ่มข้อมูลได้"
                );
              } else {
                this.$store.dispatch("logoutAuth");
                localStorage.removeItem("AUTH");
                this.$router.push("/login");
              }
            }
          });
      } else {
        toast.error("กรุณากรอกข้อมูล");
      }
    },
    onDeleteAdmin(id) {
      axios
        .post(
          "/admin/user/remove",
          {
            admin: id,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("AUTH"),
            },
          }
        )
        .then((resp) => {
          if (resp?.data?.status === "success") {
            toast.success("สำเร็จ");
            this.fetchAdminData();
          }
        })
        .catch((err) => {
          if (err?.response) {
            if (
              err?.response?.status !== 401 &&
              err?.response?.status !== 403
            ) {
              toast.error(
                err?.response?.data?.message
                  ? err?.response?.data?.message
                  : "ไม่สามารถลบข้อมูลได้"
              );
            } else {
              this.$store.dispatch("logoutAuth");
              localStorage.removeItem("AUTH");
              this.$router.push("/login");
            }
          }
        });
    },
  },
  computed: {
    ...mapGetters(["IsLoggedIn"]),
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString().toLowerCase();
          if (
            rowValue.includes &&
            rowValue.includes(this.searchQuery.toLowerCase())
          ) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
};
</script>

<style>
.el-table .el-table__header-wrapper thead th .cell {
  justify-content: center;
}
</style>
