import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import ArgonDashboard from "./plugins/argon-dashboard";
import "element-plus/lib/theme-chalk/index.css";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import store from "./store/vuex";


const appInstance = createApp(App);
appInstance.use(router);

const options = {};
appInstance.use(Toast, options);

appInstance.use(ArgonDashboard);
appInstance.use(store);
appInstance.mount("#app");
