<template>
  <div>
    <div>
      <!-- Header -->
      <div class="header bg-gradient-success py-7 py-lg-8 pt-lg-9">
        <div class="container">
          <div class="header-body text-center mb-5">
            <div class="row justify-content-center">
              <div class="col-xl-5 col-lg-6 col-md-8 px-5">
                <h1 class="text-white">Welcome!</h1>
              </div>
            </div>
          </div>
        </div>
        <div class="separator separator-bottom separator-skew zindex-100">
          <svg
            x="0"
            y="0"
            viewBox="0 0 2560 100"
            preserveAspectRatio="none"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <polygon
              class="fill-secondary"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
      </div>
      <!-- Page content -->
      <div class="container mt--8 pb-5">
        <div class="row justify-content-center">
          <div class="col-lg-5 col-md-7">
            <div class="card bg-secondary border-0 mb-0">
              <div class="card-header bg-transparent pb-2">
                <div class="text-muted text-center mt-2 mb-3">
                  <h4>Sign In</h4>
                </div>
              </div>
              <div class="card-body px-lg-5 py-lg-5">
                <div class="form-group">
                  <label for="username" class="form-control-label"
                    >Username</label
                  >
                  <input
                    type="text"
                    id="username"
                    class="form-control input-group-alternative"
                    v-model="username"
                  />
                </div>

                <div class="form-group">
                  <label for="password" class="form-control-label"
                    >Password</label
                  >
                  <input
                    type="password"
                    id="password"
                    class="form-control input-group-alternative"
                    v-model="password"
                  />
                </div>

                <div class="text-center">
                  <button
                    type="submit"
                    class="btn btn-primary my-4"
                    @click="onSubmitLogin"
                  >
                    เข้าสู่ระบบ
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import axios from "../services/axios";

const toast = useToast();

export default {
  data() {
    return {
      username: "",
      password: "",
    };
  },
  created() {
    if (this.IsLoggedIn) {
      this.$router.push("/");
    }
  },
  methods: {
    onSubmitLogin() {
      if (this.username && this.password) {
        axios
          .post("/admin/auth/login", {
            username: this.username,
            password: this.password,
          })
          .then((resp) => {
            if (resp?.data?.status === "success") {
              localStorage.setItem("AUTH", resp?.data?.token);
              this.$store.dispatch("loginAuth", resp?.data?.data[0]);
              this.$router.push("/");
            }
          })
          .catch((err) => {
            if (err?.response) {
              if (err?.response?.status !== 500) {
                toast.error(
                  err?.response?.data?.message
                    ? err?.response?.data?.message
                    : "ไม่สามารถเข้าสู่ระบบได้"
                );
              }
            }
          });
      } else {
        toast.error("กรอกข้อมูลให้ครบถ้วน");
      }
    },
  },
  computed: {
    ...mapGetters(["IsLoggedIn"]),
  },
};
</script>

<style>
.ct-notification .Vue-Toastification__toast--default {
  padding: 0;
  overflow: inherit;
  box-shadow: none;
  background-color: transparent;
}
</style>
