<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">ข้อความประกาศ</h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-12">
          <!-- MultipleCard -->
          <card header-classes="bg-transparent">
            <template v-slot:header>
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="h3 mb-0"></h5>
                </div>
              </div>
            </template>
            <div class="row text-center">
              <div class="col-12 col-md-4"></div>
              <div class="col-12 col-md-4">
                <div class="form-group">
                  <label class="font-weight-bold">ข้อความ</label>
                  <textarea
                    rows="10"
                    v-model="text"
                    class="form-control"
                  ></textarea>
                </div>
                <button class="btn btn-primary" @click="submitText">
                  ยืนยัน
                </button>
              </div>
              <div class="col-12 col-md-4"></div>
            </div>
            <div class="row mt-4 text-center">
              <div class="col-12 col-md-4"></div>
              <div class="col-12 col-md-4">
                <div class="row">
                  <label class="form-control-label">รูปภาพ</label>
                  <input
                    class="form-control"
                    ref="fileupload"
                    type="file"
                    @change="ImgfilesChange"
                  />
                </div>
                <div
                  class="row w-100 mt-3 justify-content-center"
                  v-if="previewImage"
                >
                  <div>
                    <img
                      style="max-width: 250px; height: auto"
                      :src="previewImage"
                      alt="previewImage"
                    />
                  </div>
                </div>
                <div class="row w-100 justify-content-center mt-2">
                  <button class="btn btn-danger mt-3" @click="onDeleteImage">
                    ลบรูปภาพ
                  </button>
                  <button class="btn btn-primary mt-3" @click="onAddImage">
                    ยืนยัน
                  </button>
                </div>
              </div>
              <div class="col-12 col-md-4"></div>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import axios from "../services/axios";

const toast = useToast();

export default {
  name: "AnnouncePage",
  components: {},
  data() {
    return {
      text: "",
      previewImage: "",
      image: undefined,
    };
  },
  created() {
    if (!this.IsLoggedIn) {
      this.$router.push("/login");
    } else {
      this.fetchAnnounce();
    }
  },
  methods: {
    fetchAnnounce() {
      axios
        .get("/announce")
        .then((resp) => {
          this.text = resp.data.data[0].text;
          this.previewImage = resp.data.data[0].img
            ? `https://api.bbmshop.one/images/${resp.data.data[0].img}`
            : undefined;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    submitText() {
      axios
        .post(
          "/admin/boardcast/announce",
          {
            text: this.text,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("AUTH"),
            },
          }
        )
        .then(() => {
          toast.success("สำเร็จ");
        })
        .catch(() => {
          toast.error("ไม่สามารถตั้งค่าได้");
        });
    },
    ImgfilesChange(event) {
      if (event.target.files) {
        this.image = event.target.files[0];
        this.previewImage = URL.createObjectURL(this.image);
      }
    },
    onAddImage() {
      if (this.image) {
        let fromData = new FormData();
        fromData.append("img", this.image);

        axios
          .post("/admin/boardcast/img", fromData, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("AUTH"),
            },
          })
          .then((resp) => {
            if (resp?.data?.status === "success") {
              toast.success("สำเร็จ");
              this.previewImage = "";
              this.fetchAnnounce();
            }
          })
          .catch((err) => {
            if (err?.response) {
              if (
                err?.response?.status !== 401 &&
                err?.response?.status !== 403
              ) {
                toast.error(
                  err?.response?.data?.message
                    ? err?.response?.data?.message
                    : "ไม่สามารถรูปภาพได้"
                );
              } else {
                this.$store.dispatch("logoutAuth");
                localStorage.removeItem("AUTH");
                this.$router.push("/login");
              }
            }
          });
      } else {
        toast.error("ไม่พบรูปภาพ");
      }
    },
    onDeleteImage() {
      axios
        .delete("/admin/boardcast/img", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("AUTH"),
          },
        })
        .then((resp) => {
          if (resp?.data?.status === "success") {
            toast.success("สำเร็จ");
            this.image = undefined;
            this.fetchAnnounce();
          }
        })
        .catch((err) => {
          if (err?.response) {
            if (
              err?.response?.status !== 401 &&
              err?.response?.status !== 403
            ) {
              toast.error(
                err?.response?.data?.message
                  ? err?.response?.data?.message
                  : "ไม่สามารถรูปภาพได้"
              );
            } else {
              this.$store.dispatch("logoutAuth");
              localStorage.removeItem("AUTH");
              this.$router.push("/login");
            }
          }
        });
    },
  },
  computed: {
    ...mapGetters(["IsLoggedIn"]),
  },
};
</script>

<style></style>
