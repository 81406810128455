import { createRouter, createWebHistory } from "vue-router";

import MainPageLayout from "../views/Layout/MainPageLayout.vue";
import AuthLayout from "../views/Layout/AuthLayout.vue";
import Dashboard from "../views/Dashboard.vue";
import Promotion from "../views/Promotion.vue";
import Admin from "../views/Admin.vue";
import Boardcast from "../views/Boardcast.vue";
import Summary from "../views/Summary.vue";
import GuestSummary from "../views/GuestSummary.vue";
import Announce from "../views/announce.vue";
import Messenger from "../views/Messenger.vue";
import SumProduct from "../views/SumProduct.vue";
import GuestSumProduct from "../views/GuestSumProduct.vue";
import TimeSetting from "../views/TimeSetting.vue";
import TotalSales from "../views/TotalSales.vue";
import HistoryStock from "../views/HistoryStock.vue";

// Pages
import Login from "../views/Login.vue";

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    component: MainPageLayout,
    name: "Dashboards",
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        components: { default: Dashboard },
      },
      {
        path: "/promotion",
        name: "Promotion",
        components: { default: Promotion },
      },
      {
        path: "/admin",
        name: "Admin",
        components: { default: Admin },
      },
      {
        path: "/boardcast",
        name: "Boardcast",
        components: { default: Boardcast },
      },
      {
        path: "/summary",
        name: "Summary",
        components: { default: Summary },
      },
      {
        path: "/announce",
        name: "Announce",
        components: { default: Announce },
      },
      {
        path: "/timesetting",
        name: "TimeSetting",
        components: { default: TimeSetting },
      },
      {
        path: "/messenger",
        name: "Messenger",
        components: { default: Messenger },
      },
      {
        path: "/sumproduct",
        name: "SumProduct",
        components: { default: SumProduct },
      },
      {
        path: "/totalsales",
        name: "TotalSales",
        components: { default: TotalSales },
      },
      {
        path: "/history-stock/:product_id",
        name: "HistoryStock",
        components: { default: HistoryStock },
      },
    ],
  },
  {
    path: "/",
    redirect: "/",
    component: AuthLayout,
    children: [
      {
        path: "/login",
        name: "Login",
        components: { default: Login },
      },
      {
        path: "/guest-summary",
        name: "GuestSummary",
        components: { default: GuestSummary },
      },
      {
        path: "/guest-sumproduct",
        name: "GuestSumProduct",
        components: { default: GuestSumProduct },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: "active",
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

// router.beforeEach((to, from, next) => {
//   const publicPages = ["/login"];
//   const authRequired = !publicPages.includes(to.path);
//   const loggedIn = localStorage.getItem("AUTH");

//   if (authRequired && !loggedIn) {
//     next("/login");
//   } else {
//     next();
//   }
// });

export default router;
